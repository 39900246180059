<template>
  <b-card
    class="purchaseinboundorder-edit-wrapper"
  >
    <!-- form -->
    <b-form id="purchaseinboundorderForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="发货单编号"
            label-for="in_order_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="in_order_no"
              size="sm"
              v-model="purchaseinboundorder.in_order_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单ID"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              v-model="purchaseinboundorder.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单编号"
            label-for="order_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_no"
              size="sm"
              v-model="purchaseinboundorder.order_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库单ID purchase_inbound.inbound_id"
            label-for="inbound_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbound_id"
              size="sm"
              v-model="purchaseinboundorder.inbound_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库单编号 purchase_inbound.inbound_no"
            label-for="inbound_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbound_no"
              size="sm"
              v-model="purchaseinboundorder.inbound_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="订单日期"
            label-for="add_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_date"
              size="sm"
              v-model="purchaseinboundorder.add_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="供应商ID purchase_order.supplier_id"
            label-for="supplier_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="supplier_id"
              size="sm"
              v-model="purchaseinboundorder.supplier_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="供应商名称 purchase_order.supplier_name"
            label-for="supplier_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="supplier_name"
              size="sm"
              v-model="purchaseinboundorder.supplier_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="发货总量"
            label-for="total_qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="total_qty"
              size="sm"
              v-model="purchaseinboundorder.total_qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="发货总金额"
            label-for="total_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="total_amount"
              size="sm"
              v-model="purchaseinboundorder.total_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="客户名称ID  purchase_order.company_id"
            label-for="company_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_id"
              size="sm"
              v-model="purchaseinboundorder.company_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="客户名称 purchase_order.company_name"
            label-for="company_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_name"
              size="sm"
              v-model="purchaseinboundorder.company_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="收货地址"
            label-for="location"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="location"
              size="sm"
              v-model="purchaseinboundorder.location"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="联系人"
            label-for="contact"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="contact"
              size="sm"
              v-model="purchaseinboundorder.contact"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="联系电话"
            label-for="mobile"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="mobile"
              size="sm"
              v-model="purchaseinboundorder.mobile"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="发货日期 当前时间"
            label-for="send_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="send_time"
              size="sm"
              v-model="purchaseinboundorder.send_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="到货日期 purchase_inbound.inbound_time"
            label-for="delivery_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="delivery_time"
              size="sm"
              v-model="purchaseinboundorder.delivery_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="签收日期 purchase_inbound.inbound_time"
            label-for="inbound_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbound_time"
              size="sm"
              v-model="purchaseinboundorder.inbound_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="运输方式 purchase_inbound.transport_type"
            label-for="transport_type"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="transport_type"
              size="sm"
              v-model="purchaseinboundorder.transport_type"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="承运商ID purchase_inbound.company_id_transport"
            label-for="company_id_transport"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_id_transport"
              size="sm"
              v-model="purchaseinboundorder.company_id_transport"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="承运商名称 purchase_inbound.company_name_transport"
            label-for="company_name_transport"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_name_transport"
              size="sm"
              v-model="purchaseinboundorder.company_name_transport"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="物流车型  purchase_inbound.fee_transport_setting_type"
            label-for="car_detail"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="car_detail"
              size="sm"
              v-model="purchaseinboundorder.car_detail"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="物流车牌号 purchase_inbound.car_no"
            label-for="car_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="car_no"
              size="sm"
              v-model="purchaseinboundorder.car_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库填写的入库备注 purchase_inbound.memo_wh"
            label-for="memo_wh"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="memo_wh"
              size="sm"
              v-model="purchaseinboundorder.memo_wh"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="制单人"
            label-for="inbound_creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbound_creator"
              size="sm"
              v-model="purchaseinboundorder.inbound_creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="操作时间"
            label-for="op_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="op_time"
              size="sm"
              v-model="purchaseinboundorder.op_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="启用"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="purchaseinboundorder.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="purchaseinboundorder.create_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人ID"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="purchaseinboundorder.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新时间"
            label-for="update_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="update_time"
              size="sm"
              v-model="purchaseinboundorder.update_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新人ID"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="purchaseinboundorder.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import purchaseinboundorderStore from './purchaseinboundorderStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      purchaseinboundorder: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('purchaseinboundorder')) store.registerModule('purchaseinboundorder', purchaseinboundorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinboundorder')) store.unregisterModule('purchaseinboundorder')
    })

    const edit = function() {
      store.dispatch('purchaseinboundorder/edit', {id: this.id}).then(res => {
        this.purchaseinboundorder = res.data.data
      })
    }

    const view = function() {
      store.dispatch('purchaseinboundorder/view', {id: this.id}).then(res => {
        this.purchaseinboundorder = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('purchaseinboundorder/save', this.purchaseinboundorder).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>